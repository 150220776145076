<template>
    <div style="padding-bottom: 100px;">
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="loadSuccess && !shops.length">
            很抱歉，您还未绑定任何店铺，点击下方按钮立即绑定店铺，享受高端服务
        </van-notice-bar>
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-else>
            店铺认证审核通过后才可发单
        </van-notice-bar>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;padding: 0;line-height: 39px;margin-bottom: 0;">我的店铺
            <van-button type="primary" color="linear-gradient(to right,#ff6034,#ee0a24)" round @click="apply" size="small" style="float: right;margin: 5px 10px 0 0;padding: 0 20px;">添加店铺</van-button>
        </p>
        <van-empty v-if="!shops.length" description="暂无店铺" />
        <div class="container">
            <van-card
                    v-for="item in shops"
                    :desc="item.short_name + item.address"
                    :thumb="item.head_img">
                <div slot="title">
                    <p style="font-weight: bold;font-size: 15px;margin: 0 0 5px;">
                        <van-tag style="margin-right: 5px;" plain v-if="item.verified === -1">{{item.verified_name}}</van-tag>
                        <van-tag style="margin-right: 5px;" plain v-if="item.verified === 0 ">{{item.verified_name}}</van-tag>
                        <van-tag style="margin-right: 5px;" type="success" v-if="item.verified === 1">{{item.verified_name}}</van-tag>
                        <van-tag style="margin-right: 5px;" type="danger" v-if="item.verified === 2">{{item.verified_name}}</van-tag>

                        <span style="line-height: 20px;display: inline-block;">{{item.shop_name}}</span>
                    </p>
                </div>
                <div slot="desc">
                    <p style="color: #666;font-size: 12px;margin: 0 auto;">{{item.short_name + '-' + item.address}}</p>
                    <p style="font-size: 14px;margin: 2px auto;color: red;" v-if="item.verified === 2">审核信息:{{item.verify_remark}}</p>
                </div>
                <template #tag>
                    <van-tag mark type="primary">{{item.type_name}}</van-tag>
                </template>
                <template #tags v-if="item.key_word">
                    关键词：<van-tag plain style="margin: 5px 0;">{{item.key_word}}</van-tag>
                </template>
                <template #footer>
                    <van-button size="mini" style="padding:10px;margin-right: 5px;" color="#e2ba7f" round v-if="item.lottery_permit" @click="$router.push({ path: '/shop/goods/list', query: { shop_id: item.shop_id,shop_name:item.shop_name }})">添加团购</van-button>
                    <van-button size="mini" style="padding:10px;" round  @click="$router.push({ path: '/shop/bind', query: { shop: item }})">编辑</van-button>
                </template>
            </van-card>
<!--            <div v-for="item in shops">-->
<!--                <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;line-height: 24px;">-->
<!--                    <span>{{item.shop_name}}</span>-->
<!--                </div>-->
<!--                <van-cell-group class="shop-cell">-->
<!--                    <van-cell title="店铺类型" :value="item.type_name" />-->
<!--                    <van-cell title="联系人" :value="item.contact_man" />-->
<!--                    <van-cell title="联系电话" :value="item.contact_phone" />-->
<!--                    <van-cell title="店铺区域" v-if="item.short_name" :value="item.short_name" />-->
<!--                    <van-cell title="店铺地址" v-if="item.address" :value="item.address" />-->
<!--                    <van-cell title="店铺平台" v-if="item.platform" :value="item.platform" />-->
<!--                    <van-cell v-if="item.link_url" title="店铺链接">-->
<!--                        <a :href="item.link_url.substr(item.link_url.indexOf('http'))">{{item.link_url}}</a>-->
<!--                    </van-cell>-->
<!--                    <van-cell>-->
<!--                        <div style="float: right;">-->
<!--                            <van-button size="mini" style="height: 26px;line-height: 26px;padding:10px;margin-right: 5px;" color="#e2ba7f" round v-if="item.lottery_permit" @click="$router.push({ path: '/shop/goods/list', query: { shop_id: item.shop_id,shop_name:item.shop_name }})">活动设置</van-button>-->
<!--                            <van-button size="mini" style="height: 26px;line-height: 26px;padding:10px;" round  @click="$router.push({ path: '/shop/bind', query: { shop: item }})">编辑</van-button>-->
<!--                        </div>-->
<!--                    </van-cell>-->
<!--                </van-cell-group>-->
<!--            </div>-->
        </div>
        <shop-tabbar-dialog :active="3" @navigate="navigate" v-if="verified_shop"></shop-tabbar-dialog>
        <phone-bind ref="phoneBind" @success="bindPhoneSuccess"></phone-bind>
    </div>
</template>

<script>
    import PhoneBind from "../Common/Dialog/PhoneBind";
    import ShopTabbarDialog from "../Common/Dialog/ShopTabbarDialog";
    export default {
        name: "Shop",
        components:{
            PhoneBind,
            ShopTabbarDialog,
        },
        data(){
            return{
                loadSuccess:false,
                shops:[],
                verified_phone:false,
                verified_shop:false,
            }
        },
        mounted() {
            this.loadData();
        },
        methods:{
            navigate(page){
                this.$router.replace(page);
            },
            loadData(){
                this.post('/api/client/shop/store',{}).then(result=> {
                    if (result.code === 0) {
                        this.loadSuccess = true;
                        this.shops = result.data.info;
                        this.verified_phone = result.data.verified_phone;;
                        this.verified_shop = result.data.verified_shop;
                    }
                });
            },
            apply(){
                // 检查当前用户是否绑定了手机号
                if(!this.verified_phone){
                    this.$refs.phoneBind.init();
                    return;
                }
                this.$router.push('/shop/bind');
            },
            bindPhoneSuccess(){
                this.verified_phone = true;
                this.apply();
            },
        }
    }
</script>

<style scoped>
    .container>div{
        margin: 10px;
        border: 1px solid #eee;
        border-radius: 5px;
    }
    .shop-cell{
        border-radius:0 0 5px 5px;
        overflow: hidden;
    }
    .shop-cell .van-cell__title{
        flex: 0 0 60px;
    }
    .shop-cell .van-cell__value{
        flex: 1 1 auto;
    }
</style>